import React from "react"

import Layout from "../layout"

import Article from "./Article"

const ArticlePage = (props) => {

  return(
  <Layout >
    <Article location={props.location}/>
  </Layout>
  )
}

export default ArticlePage
